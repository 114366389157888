import { responseError } from 'FIELDS';

const withNotificationForError = (payloadCreator) => {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI);
    } catch (error) {
      responseError(error);
      throw error;
    }
  };
};

const checkPermissions = (menuItems, acl, user) => {
  const effectivePermissions = [...acl];

  if (user?.fx_xe_enabled || user?.fx_interbank_enabled) {
    if (user?.fx_xe_enabled) {
      effectivePermissions.push('fx.xe.view');
    }

    if (user?.fx_interbank_enabled) {
      effectivePermissions.push('fx.interbank.view');
    }

    effectivePermissions.push('fx.view');
  }

  const filtered = menuItems
    .filter(
      (item) =>
        !item.permissions ||
        item.permissions.some((permission) => effectivePermissions.includes(permission))
    )
    .map((item) => {
      const newItem = { ...item };

      if (newItem.subItems) {
        newItem.subItems = checkPermissions(newItem.subItems, effectivePermissions, user);
      }

      return newItem;
    });

  return filtered.filter((item) => !item.subItems || item.subItems.length > 0);
};

export { withNotificationForError, checkPermissions };
